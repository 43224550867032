<template>
    <div class="card p-2" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>PO No</th>
                    <th>PO Date</th>
                    <th>Supplier</th>
                    <th>Project</th>
                    <th>Department</th>
                    <th>PO Type</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in list.data" :key="i">
                    <td>{{ item.po_number }}</td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.contact_profile?.full_name }}</td>
                    <td>{{ item.project?.name }}</td>
                    <td>{{ item.department?.name }}</td>
                    <td>{{ item.po_type }}</td>
                    <td>{{ item.status }}</td>
                    <td>
                        <button class="btn btn-secondary btn-sm" title="Landed Costing"
                                @click="goTo(item)"
                                style="cursor: pointer; margin-left: 10px"
                        >
                            <i class="fas fa-eye"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
            <p v-if="!list.total" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";

const props  = defineProps({
    list: Object
});
const route  = useRoute();
const router = useRouter();

function goTo(item) {
    let param = {
        companyId: route.params.companyId,
        moduleId : route.params.moduleId,
        menuId   : route.params.menuId,
        pageId   : route.params.pageId,
    }

    let query = route.query;
    query.purchase_order_id = item.id;

    router.push({
        name  : 'landed-costing-add',
        params: param,
        query : query
    })
}
</script>
